h1 {
  font-size: 20px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 18px !important;
  font-weight: bold !important;
}

h3 {
  font-size: 16px !important;
  font-weight: bold !important;
}

h4 {
  font-size: 14px !important;
  font-weight: bold !important;
}

.semiHead-1 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.semiHead-2 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.semiHead-3 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.semiHead-4 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.semiHead-5 {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.mediumHead-1 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.mediumHead-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mediumHead-3 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.regularContent-1 {
  font-size: 20px !important;
  font-weight: 400 !important;
}

.regularContent-2 {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.regularContent-3 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.regularContent-4 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.boldContent-1 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.card-company {
  position: relative;
  width: 500px;
  background-color: #283142;
  text-align: center;
}

.card-company .semicircle-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  /* Adjust the height as needed */
  background-image: linear-gradient(to right, #444d4e, #6a8f8f);
  border-radius: 0 0 50px 50px;
  /* To create a semicircle at the bottom */
  z-index: 2;
}

.card-company .title-total {
  position: relative;
  z-index: 3;
}

.card-company::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* Adjust the width as needed */
  /* background-image: linear-gradient(to right, #78bbc7, #6a8f8f); */
}

.card-company::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* Adjust the width as needed */
  /* background-image: linear-gradient(to right, #78bbc7, #6a8f8f); */
}

.drawer-dash-monthly {
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  text-align: center;
  margin: 2px;
}

.chat .chat-content .chat-content-body .msg {
  display: block !important;
}

.msg-recipient .bubble {
  float: left !important;
}

.msg-sent .bubble {
  float: right !important;
}


.student-parent-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  background-color: #bc404f;
  /* Background color */
  clip-path: polygon(0% 0%, 100% 0%, 100% 10%, 50% 100%, 0% 10%);
  /* Clip-path for custom shape */
  z-index: 1;
}

.student-parent-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(135deg, #bc404f, #e7919b);
  /* Gradient colors */
  border-bottom-left-radius: 15px;
  /* Adjust as needed */
  border-bottom-right-radius: 15px;
  /* Adjust as needed */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
  z-index: 1;
}

/* editorStyles.css */

/* styles.css */

.fb-editor-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.wrapper-class {
  color: "black"
}

.editor-class {
  min-height: 200px;
}

.toolbar-class {
  color: "black"
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* New Parent Dashbaord */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.student-card-dashboard .student-card {
  padding: 24px 32px 24px 32px !important;
  border-radius: 10px;
  text-align: center;
}

.student-card-dashboard h6 {
  color: #202020;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.student-card-dashboard .student-id {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.student-card-dashboard h5 {
  color: #202020;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.student-card-dashboard .student-label {
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.student-card-dashboard .student-value {
  color: #3C3C3C;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.student-card-dashboard button {
  color: #000000;
  background-color: #F5F5F5;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 8.97px;
  padding: 6.73px 17.94px 6.73px 17.94px;
}

.student-card-dashboard .yellow-border {
  display: flex;
  height: 34px;
  width: 4px;
  border-radius: 2px;
  background: #FBD409;
}

.student-card-dashboard .event-head {
  font-weight: 400;
  font-size: 10px;
}

.student-card-dashboard .col-border-right {
  border-right: 0px solid #E6E6E6;
  min-width: 210px;
}

.parent-chart-card .card {
  height: 350px;
}

.custom-progress-container .ant-progress-inner {
  width: 170px !important;
  height: 170px !important;
}

.parent-dashboard-2 .submitted-box {
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  border: 1px solid #47A580;
  background-color: #F4FFFB;
}

.parent-dashboard-2 .pending-box {
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  border: 1px solid #FFA629;
  background-color: #FFFBF5;
}

.parent-dashboard-2 .divider-1 {
  border: 3px solid #F9C674 !important;
  height: 26px;
  border-radius: 2px;
  width: 4px;
}

.parent-dashboard-2 .divider-2 {
  border: 3px solid #6489E1 !important;
  height: 26px;
  border-radius: 2px;
  width: 4px;
}

.subject-table .ant-table-thead {
  display: none;
}

.subject-table .ant-table-pagination {
  display: none;
}

.strongest-topic {
  font-weight: 400;
  font-size: 12px;
  color: #3C3C3C;
  border: 1px solid #47A580;
  padding: 7px 10px 3px 10px;
  border-radius: 4px;
  background-color: #F4FFFB;
}

.weakest-topic {
  font-weight: 400;
  font-size: 12px;
  color: #3C3C3C;
  border: 1px solid #C90707;
  padding: 7px 10px 3px 10px;
  border-radius: 4px;
  background-color: #FFF9F9;
}

.parent-dashboard-2 .card-height-1 {
  height: 595px;
}

.parent-dashboard-2 .card-height-2 {
  height: 460px;
}

.week-pill {
  border: 1px solid #47A580;
  background-color: #F9F9F9;
  padding: 8px 12px 8px 12px;
  border-radius: 58px;
}

.selected-week-pill {
  border: 1px solid #47A580;
  background-color: #47A580;
  color: white !important;
  padding: 8px 12px 8px 12px;
  border-radius: 58px;
}

.sunday-pill {
  border: 1px solid #D5433A;
  background-color: #F9F9F9;
  padding: 8px 12px 8px 12px;
  border-radius: 58px;
}

.number-weak {
  font-size: 10px;
  color: #000000;
  padding: 3px 4px 3px 4px;
  border-radius: 29px;
  background-color: #ffffff;
}

.sunday-weak {
  font-size: 10px;
  color: #ffffff;
  padding: 3px 4px 3px 4px;
  border-radius: 29px;
  background-color: #D5433A;
}

.name-week {
  font-size: 10px;
  color: #000000;
}

.selected-week {
  font-size: 10px;
  color: #ffffff;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* New Parent Assignments */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.parent-assignment .ant-tabs-nav-list,
.anounce-events .ant-tabs-nav-list {
  /* border: 1px solid #E6E6E6; */
  border-radius: 5px;
}

.parent-assignment .ant-tabs-tab,
.anounce-events .ant-tabs-tab {
  border: 1px solid #E6E6E6;
  background-color: white;
  border-radius: 4px;
  padding: 10px 16px 10px 16px !important;
}

.parent-assignment .ant-tabs-tab-active,
.anounce-events .ant-tabs-tab-active {
  background-color: #F4F4F4 !important;
  color: #000000 !important;
}

.parent-assignment .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.anounce-events .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}

.parent-assignment .ant-tabs-ink-bar,
.anounce-events .ant-tabs-ink-bar {
  background: none !important;
}

.parent-assignment .ant-tabs-top>.ant-tabs-nav::before,
.anounce-events .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none !important;
}

.parent-assignment button {
  color: #000000;
  background-color: #F5F5F5;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 6px;
  padding: 2px 16px 2px 16px !important;
}

.bullet-bold {
  font-weight: bold;
  font-size: 15px;
  /* Adjust the size of the bullet */
  color: #E9E9E9;
  /* Adjust the color of the bullet */
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* New Parent Request */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.parent-request .req-color {
  color: #3C3C3C;
}

.parent-request .bg-req-color {
  background-color: #E2E2FF;
}

.parent-request .rounded-100 {
  border-radius: 50px;
  background-color: #C94941 !important;
  padding: 8px 14px 8px 14px !important;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* New Parent Courses */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.parent-courses .courses-card {
  padding: 24px 32px 24px 32px !important;
  border-radius: 10px;
  text-align: center;
}

.course-bg {
  border-radius: 8px;
  padding: 12px;
  width: 41px;
  height: 40px;
}

.color-bg-1 {
  background-color: #F9C674;
}

.color-bg-2 {
  background-color: #02C3A8;
}

.color-bg-3 {
  background-color: #6489E1;
}

.color-bg-4 {
  background-color: #FD7066;
}

.parent-courses .ant-progress-inner {
  width: 22px !important;
  height: 22px !important;
  font-size: 0px !important;
}

.parent-courses .btn-view {
  background-color: #F5F5F5;
  padding: 4px 12px 4px 12px !important;
  border-radius: 5px;
  color: #000000;
  font-weight: 500;
  margin-top: -10px;
}

.parent-courses .progress-content {
  color: #939393;
  font-size: 12px;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* New Parent Fees */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.fees-filter {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}

.fees-filter .card {
  border-radius: 0px !important;
}

.outstanding-fees {
  border-radius: 8.97px;
  padding: 8px 12px 8px 12px;
  border: 1px solid #D5433A;
  flex-direction: column;
  color: #D5433A !important;
}

.outstanding-fees h5 {
  color: #D5433A !important;
}

.outstanding-fees span {
  margin-top: -10px;
}

.total-fees {
  border-radius: 8.97px;
  padding: 8px 12px 8px 12px;
  border: 1px solid #54B227;
  flex-direction: column;
  color: #54B227 !important;
}

.total-fees h5 {
  color: #54B227 !important;
}

.total-fees span {
  margin-top: -10px;
}

.parent-fees .btn-view {
  padding: 8px 12px 8px 12px;
  border-radius: 8.97px;
  background-color: #F5F5F5;
  color: #000000;
}

.parent-donut .ant-card-bordered {
  border: none !important;
}

.help-center .ant-collapse-arrow {
  color: #B91010 !important;
}

.help-center .ant-collapse>.ant-collapse-item {
  border: 1px solid #B91010;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
}

.help-center .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
  padding: 8px 12px 8px 12px !important;
}

.purchase-form .ant-form label {
  width: 115px !important;
  text-align: center !important;
}

.all-charges .box {
  width: 16px;
  height: 16px;
  border-radius: 99px;
  margin-right: 10px;
  margin-top: 7px;
  display: flex;
}

.all-charges .box-1 {
  background-color: #F9DE53;
}

.all-charges .box-2 {
  background-color: #036AE3;
}

.all-charges .box-3 {
  background-color: #96D544;
}

.all-charges button {
  background-color: #F5F5F5;
  padding: 8px 12px 8px 12px;
  border-radius: 8.97px;
  color: #000000;
}

.all-charges .card-1 {
  height: 400px;
}

.all-charges .card-2 {
  height: 400px;
}

.anounce-events button {
  color: #000000;
  background-color: #F5F5F5;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 6px;
  padding: 6.73px 17.94px 6.73px 17.94px;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* Student Attendance Dashboard */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.attendance-percent-tag {
  border: 1px solid #B91010;
  display: flex;
  border-radius: 16px;
  color: #B91010;
  background-color: #f7e7e7;
  padding: 0px 7px;
}

.detail-attendance-card {
  min-width: 180px;
  width: 200px;
}

.field-item {
  display: list-item;
  list-style-type: disc;
}

.present-attendance {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #54B227;
  color: #54B227;
}

.excused-attendance {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #FF754A;
  color: #FF754A;
}

.unexcused-attendance {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #D5433A;
  color: #D5433A;
}

.late-attendance {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #FBD409;
  color: #FBD409;
}

.unspecified-attendance {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #B0AFAD;
  color: #B0AFAD;
}

.bg-link-req {
  cursor: pointer;
}

.truncated-text {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncated-text:hover::after {
  content: attr(title);
  position: absolute;
  white-space: normal;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-width: 400px;
  /* Adjust as needed */
}